import * as React from "react";
import Layout from "../components/common/Layout";
import Footer from "../components/common/Footer";
import {
  Body,
  StyledBodyText,
  TextH2,
} from "../components/common/common-styles";
import styled from "styled-components";
import SectionHeader from "../components/common/SectionHeader";
import ContactForm from "../components/common/ContactForm";
// @ts-ignore
import NathanImage from "../images/nathan.jpeg";
// @ts-ignore
import RogerImage from "../images/roger.jpeg";
// @ts-ignore
import CharlieImage from "../images/charlie.jpeg";
import { EmployeeCard } from "../components/about/EmployeeCard";
import OurProcess from "../components/about/OurProcess";
// markup

const StyledBody = styled(Body)`
  width: 100%;
  display: flex;
  max-width: 1050px;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`;

const EmployeeList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;

const About = () => {
  return (
    <Layout pageTitle="About Us">
      <SectionHeader text={"ABOUT US"} />
      <StyledBody>
        <TextH2>Building Partnerships</TextH2>
        <StyledBodyText>
          We offer a unique approach to software consulting, prioritizing a true
          partnership with clients and focusing on customized solutions for both
          short-term and long-term success. By nurturing a happy and secure
          workforce with meaningful benefits, Waypoint ensures low turnover and
          a consistent team dedicated to making a positive impact on clients and
          employees alike. Reach out and partner with a company that aims to be
          your trusted partner in enhancing your business.
        </StyledBodyText>
      </StyledBody>
      <EmployeeList>
        <EmployeeCard
          name={"Nathan Haines"}
          photo={NathanImage}
          link={"https://www.linkedin.com/in/the-nathan-haines/"}
          role={"Owner"}
        />{" "}
        <EmployeeCard
          name={"Roger Schlachter"}
          photo={RogerImage}
          link={"https://www.linkedin.com/in/roger-schlachter-b5b69920/"}
          role={"Owner"}
        />{" "}
        <EmployeeCard
          name={"Charlie Horning"}
          photo={CharlieImage}
          link={"https://www.linkedin.com/in/charlie-horning-98514b7/"}
          role={"Managing Partner"}
        />
      </EmployeeList>
      <OurProcess />
      <ContactForm />

      <Footer />
    </Layout>
  );
};

export default About;
