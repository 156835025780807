import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import WaypointPattern from "../../images/waypoint-pattern-white.png";
import DevImage from "../../images/out-process.jpg";
import Dots from "../../images/circle-pattern.png";
import SectionHeader from "../common/SectionHeader";
import { TextH2 } from "../common/common-styles";

const Section = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
`;

const SectionBody = styled.div`
  max-width: 1500px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

const BodyText = styled.div`
  font-family: "Lato Light";
  font-size: 20px;
  width: 50%;
  padding-left: 50px;
  padding-top: 54px;
  padding-right: 50px;
  width: 100%;
  @media (max-width: 700px) {
    width: 100%;
    padding-top: 0;
  }
`;

const SubHeader = styled.h4`
  font-family: "Lato";
  font-size: 24px;
  color: #00aeef;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ImageSection = styled.div`
  width: 50%;
  position: relative;
  overflow: hidden;
  float: right;
  margin-left: 30px;
  text-align: center;
  @media (max-width: 700px) {
    display: none;
  }
`;

const OurProcess = () => {
  return (
    <Section>
      <SectionHeader text={"OUR PROCESS"} />
      <SectionBody>
        <BodyText>
          <ImageSection>
            <img style={{ width: "90%" }} src={DevImage} alt={""} />
            <img
              style={{
                opacity: 0.8,
                width: "45%",
                height: "auto",
                left: "37px",
                top: "-15%",
                position: "absolute",
              }}
              src={Dots}
              alt={"hi"}
            />
          </ImageSection>
          <TextH2>Working With Waypoint</TextH2>
          Our relationship typically begins with a kickoff call, where we learn
          about the problems you need solved. During this process you'll meet
          several members of the Waypoint team; many of which will be part of
          your project from start to finish.
          <SubHeader>DISCOVERY</SubHeader>
          Every project is different, and as a result most of our clients start
          with a discovery phase where we can get a closer look at your budget,
          needs, and timeline. This typically can take anywhere from two days to
          two weeks.
          <SubHeader>PROJECT</SubHeader>
          Once we move into the project phase you'll have frequent and clear
          communication with your Waypoint team. This includes an Executive
          Sponsor, a Waypoint executive assigned to your project to help keep
          things running smoothly. Questions or concerns during the project?
          You've already got a Waypoint decision-maker to reach out to who is
          intimately familiar with your project.
          <SubHeader>WRAP-UP</SubHeader>
          Our work with you doesnt end when the project does. When our
          consultants leave, we set you up for future success. That can be as
          simple as clear documentation, or as advanced as custom offboarding
          plan.
        </BodyText>
      </SectionBody>
    </Section>
  );
};

export default OurProcess;
