import styled from "styled-components";
import { ItalicText, TextH2 } from "../common/common-styles";
import * as React from "react";

const Card = styled.div`
  width: 300px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  flex-direction: column;
  position: relative;
`;
const ImageGradient = styled.div`
  position: relative;
  display: inline-block;

  :after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    height: 300px;
    width: 300px;
    background-image: linear-gradient(
      180deg,
      rgba(216, 216, 216, 0) 34%,
      rgba(213, 215, 216, 0.01) 46%,
      #00719b 81%
    );
    opacity: 0.6;
  }
`;

const RoleText = styled.div`
  font-family: "Lato";
  font-size: 20px;
  color: white;
  position: absolute;
  left: 10px;
  bottom: 45px;
`;
export function EmployeeCard({
  name,
  photo,
  link,
  role,
}: {
  name: string;
  photo: string;
  link: string;
  role: string;
}) {
  return (
    <a style={{ textDecoration: "none", color: "#2e353f" }} href={link}>
      <Card>
        <ImageGradient>
          <img
            src={photo}
            width={300}
            height={300}
            alt={name}
            style={{ filter: "grayscale(1)" }}
          />
        </ImageGradient>
        <TextH2
          color={"white"}
          // @ts-ignore
          size={"32px"}
          style={{ position: "absolute", bottom: 45, left: 10 }}
        >
          {name}
        </TextH2>
        <RoleText>{role}</RoleText>
      </Card>
    </a>
  );
}
